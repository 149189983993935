import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitudesService {
  url = environment.api + '/Solicitudes/';
  idInscrito;
  inscrito: Subject<any> = new Subject<any>();
  constructor(private httpClient: HttpClient) { }

  getAllSolicitudes() {
    return this.httpClient.get(this.url+"all");
  }

  getInformacionbasica(id){
    return this.httpClient.get(this.url+"informacionBasica/"+id);
  }

  getPaises(){
    return this.httpClient.get(environment.api+'/paises/'); 
  }

  getDepartamentos(){
    return this.httpClient.get(environment.api+'/departamentos/'); 
  }

  getCiudades(){
    return this.httpClient.get(environment.api+'/ciudades'); 
  }

  getCiudadesDepartamento(idDepartamento){
    return this.httpClient.get(environment.api+'/ciudades/Depto/'+idDepartamento);
  }

  getUniversidades(){
    return this.httpClient.get(environment.api+'/Universidades/'); 
  }

  getUrlDocumentos(ruta){
    return this.httpClient.get(environment.api + '/AdministradorS3/documentoMatricula?url=' + ruta + '&download=false', { responseType: 'text' })
  }

  getUrlDocumentoBucket(ruta,bucket,contentType){
    return this.httpClient.get(environment.api + '/AdministradorS3/documentoBucket?url=' + ruta + '&download=false&bucket='+bucket+'&contentType='+contentType, { responseType: 'text' })
  }

  getInfoInscrito(matricula,docIdentidad){
    return this.httpClient.get(environment.api + '/inscritos', {
      params: {
        matricula: matricula,
        docIdentidad: docIdentidad
      }
    })
  }

  getInformacionAdicional(id){
    return this.httpClient.get(this.url+"informacionAdicional/"+id);
  }


  updateInformacionBasica(id,formData:FormData){  
    return this.httpClient.post(this.url+"informacionBasica/"+id, formData);
  }

  procesarPostgrado (postgrado){  
    return  this.httpClient.post(environment.api + '/Posgrado/ProcesarPostgrados', postgrado);
  }

  addPostgrado (postgrado){
    return  this.httpClient.post(environment.api + '/Posgrado/AgregarPostgrado', postgrado);
  }  

  updateInformacionLaboral(id,informacionLaboral){
    return  this.httpClient.post(environment.api + '/InfomacionLaboral/UpdateInfoLaboral/'+id, informacionLaboral);
  }

  getDocumentosSolicitud(id){
    return this.httpClient.get(this.url+"documentosSolicitud/"+id);
  }

  getNombrePersonalizadoArchivo(KeyFolio,idInscrio){
    return  this.httpClient.get(environment.api + '/DocumentosInscrito', {
      responseType: 'text',
      params: {
        keyFolio: KeyFolio,
        idInscrito: idInscrio
      }
    })
  }


  getProcesarDocumentosSolicitud(formData){
    return this.httpClient.post(this.url+"procesarDocumentos",formData);
  }

  enviarNotificacion(dataEmail){
    return this.httpClient.post(this.url+"enviarNotificacion",dataEmail);
  }

  dowloadFile(url){
    return  this.httpClient.get(environment.api + '/AdministradorS3/documentoMatricula/?url=' + url + '&download=true', { responseType: 'text' });
  }

  getSolicitudes(idInscrito){
    return this.httpClient.get(this.url+"solicitudesInscrito/"+idInscrito);
  }

  actualizarSolcitiudMatricula(data){
    return this.httpClient.post(this.url+"actualizarSolcitiudMatricula",data);
  }

  sendResolucionMatricula(data){
    return this.httpClient.post(this.url+"sendResolucionMatricula",data);
  }


  calificarInformacionConsejero(id,formData:FormData){  
    return this.httpClient.post(this.url+"informacionBasica/calificarInformacion/"+id, formData);
  }

  dowloadFileBucket(url,bucket){
    return  this.httpClient.get(environment.api + '/AdministradorS3/urlDocumentoBucket/?url=' + url + '&bucket='+bucket+'&download=true', { responseType: 'text' });
  }
 
  


  


  
}
