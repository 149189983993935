<div style="display: flex; width: 100%;">
    <div class="barra"></div>
    <div style="display: flex; width: 5%; margin-left: 8px; margin-right: 8px;" [mat-dialog-close]="false"><img src="../../../../../../assets/cerrar.svg" width="40px" style="cursor: pointer;"></div>  
  </div>
  <div class="container" *ngIf="documentosDenuncia">
      <div style="text-align: center;"><b>Documentos de la Actuación</b></div>  
      <table>
        <tr class="titulo">
          <td>Nombre</td>
          <td *ngIf="mostrarInternos == true">Tipo</td>
          <td>Ver</td>
        </tr>
        <tr *ngFor="let documento of documentosDenuncia">         
            <td style="width: 80%; text-align: left;" *ngIf="((documento.esPublico == 1) || (documento.esPublico==0 && mostrarInternos == true))">{{documento.nombre}}</td>
            <td *ngIf="mostrarInternos == true"> <span *ngIf="((documento.esPublico == 1) || (documento.esPublico==0 && mostrarInternos == true))">{{documento.esPublico == 1 ? "Público" : "Interno"}}</span></td>
            <td *ngIf="((documento.esPublico == 1) || (documento.esPublico==0 && mostrarInternos == true))"><mat-icon (click)="VerDocumento(documento.url)">remove_red_eye</mat-icon></td>          
      </tr>
      </table>
    
    </div>
    
