<div class="container-externa" [ngClass]="{'scale': scale}">
  <mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="over" [(opened)]="opened">
      <div class="close">
        <mat-icon style="color: #009F1F" (click)="opened = !opened">close</mat-icon>
        <p style="margin-right: auto">CONALPE</p>
        <img style="margin-right: 1rem" src="../../assets/1-logo.svg" height="54px">
      </div>
      <button class="extra" mat-button routerLink="/" (click)="opened = !opened">Home</button>
      <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header class="panel">
            <p>Trámites y servicios</p>
          </mat-expansion-panel-header>
          <button mat-button routerLink="/tramitesyservicios/matriculas-profesionales" (click)="opened = !opened">
            Matrículas profesionales
          </button>
          <button mat-button routerLink="/tramitesyservicios/certificado" (click)="opened = !opened">Certificados
            trámite y vigencia
          </button>
          <button mat-button routerLink="/tramitesyservicios/duplicado" (click)="opened = !opened">Duplicados</button>
          <button mat-button routerLink="/tramitesyservicios/Consulta-Inscritos" (click)="opened = !opened">Estado del
            trámite
          </button>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0 ">
          <mat-expansion-panel-header class="panel">
            <p>Inspección y vigilancia</p>
          </mat-expansion-panel-header>
          <button mat-button routerLink="/inspeccion-vigilancia/denunciar" (click)="opened = !opened">Denuncia o queja
            profesional
          </button>
          <button mat-button routerLink="/inspeccion-vigilancia/busqueda-procesos" (click)="opened = !opened">
            Búsqueda de Procesos
          </button>
          <button mat-button routerLink="/inspeccion-vigilancia/lista-sancionados" (click)="opened = !opened">
            Sancionados
          </button>
          <button mat-button routerLink="/inspeccion-vigilancia/notificaciones/aviso" (click)="opened = !opened">
            Notificaciones
          </button>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header class="panel">
            <p>Universidades y eventos</p>
          </mat-expansion-panel-header>
          <button mat-button routerLink="/universidadesyeventos/universidades" (click)="opened = !opened">
            Universidades
          </button>
          <button mat-button routerLink="/universidadesyeventos/eventos" (click)="opened = !opened">Eventos</button>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header class="panel">
            <p>Nosotros</p>
          </mat-expansion-panel-header>
          <button mat-button routerLink="/nosotros/cultura" (click)="opened = !opened">Cultura organizacional</button>
          <button mat-button routerLink="/nosotros/historia" (click)="opened = !opened">Historia</button>
          <button mat-button routerLink="/nosotros/directivos" (click)="opened = !opened">Directivos</button>
          <button mat-button routerLink="/nosotros/marco-legal" (click)="opened = !opened">Marco Legal</button>
          <button mat-button routerLink="/nosotros/gestion-institucional" (click)="opened = !opened">Gestión
            Financiera
          </button>
          <button mat-button routerLink="/nosotros/politicas-manuales" (click)="opened = !opened">Gestión
            Institucional
          </button>
          <button mat-button routerLink="/nosotros/consejos-colegios" (click)="opened = !opened">Otros Consejos y Colegios
          </button>
        </mat-expansion-panel>
      </mat-accordion>
      <button mat-button class="extra" routerLink="/noticias" (click)="opened = !opened">Noticias</button>
      <button mat-button class="extra" routerLink="/tramitesyservicios/Consulta-Inscritos" (click)="opened = !opened">
        Consulta de Matrícula
      </button>
      <button mat-button class="extra" routerLink="/contacto" (click)="opened = !opened">Contacto</button>
      <button mat-button class="extra" routerLink="/intranet">Intranet</button>

    </mat-sidenav>
    <mat-sidenav #sidenavright position="end" mode="over" [(opened)]="chatnav" class="chat-nav">
      <div class="chat-content">
        <div class="header">
          <div class="bg-green">
            <p class="name">Jorge</p>
          </div>
          <div class="close-wrapper">
            <img class="close-icon" src="../../assets/Cerrar.PNG" alt="" (click)="chatnav = false">
          </div>
        </div>
        <div class="chat" #chat [scrollTop]="chat.scrollHeight">
          <div *ngFor='let m of chatMessages; let i = index' class="{{m.Owner}}">
            <p class="text" *ngIf="i != 0">{{m.Owner}}:
              <span [innerHTML]="clearmessage(m.Message)"></span>
              <br>
              <span
                *ngIf="m.ResponseCard && m.Message.includes('**') == false"> {{m.ResponseCard.genericAttachments[0].title}}</span>
            </p>
            <div *ngIf="m.ResponseCard">
              <div *ngFor="let cards of m.ResponseCard.genericAttachments">
                <div *ngFor="let button of cards.buttons">
                  <button mat-button (click)="select(button.value)">
                    <div [innerHTML]="button.text"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input">
          <form action=" " (ngSubmit)="pushChat(chatform.value)" #chatform="ngForm">
            <mat-form-field class="example-full-width">
              <input type="text" id="chatInput" matInput placeholder="Escribe el mensaje..." autocomplete="off"
                     [disabled]="chatMessages[chatMessages.length-1].Message != 'Favor digite el número de radicado'"
                     [(ngModel)]=ChatInput name="chatInput">
              <mat-icon matSuffix disabled="true">send</mat-icon>
            </mat-form-field>
          </form>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="chat-bot">
        <img src="../../assets/Chatbot.png" alt="" width="100%" height="100%" (click)="chatnav = !chatnav">
      </div>
      <div class="social-network">
        <a href="https://www.facebook.com/conalpe" target="_blank">
          <img src="../../assets/iconmonstr-facebook-1.svg" alt="">
        </a>
        <a href="https://twitter.com/ConalpeDefensa"  target="_blank">
          <img src="../../assets/iconmonstr-twitter-1.svg" alt="">
        </a>
        <a href="https://www.instagram.com/conalpe1/"  target="_blank">
          <img src="../../assets/iconmonstr-instagram-11.svg" alt="">
        </a>
        <a href="https://www.youtube.com/channel/UCYp-BF8g6wMjI7h0tFpt4Kg/featured"  target="_blank">
          <img src="../../assets/YouTube.svg" alt="" width="24px">
        </a>
      </div>
      <div class="content-example-barra ">
        <div class="barra-accesibilidad-govco">
            <button id="botoncontraste" class="icon-contraste" onclick="cambiarContexto()">
                <span id="titlecontraste">Contraste</span>
            </button>
            <button id="botondisminuir" class="icon-reducir" onclick="disminuirTamanio('disminuir')">
                <span id="titledisminuir">Reducir letra</span>
            </button>
            <button id="botonaumentar" class="icon-aumentar" onclick="aumentarTamanio('aumentar')">
                <span id="titleaumentar">Aumentar letra</span>
            </button>
            <button id="botoncentro" class="icon-centro">
              <a id="c_relevo" href="https://centroderelevo.gov.co/632/w3-channel.html" target="_blank" class="icon-a-less" title="Ir a Centro de Relevo">
                <!--<img src="../../assets/channels-616_icon_centro_relevo.svg" alt="Botón Reducir el tamaño del texto" class="img-fluid">-->
                <span id="titleaumentar2">Centro de relevo</span>
            </a>
          </button>
        </div>
      </div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
