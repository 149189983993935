<div style="display: flex;">
    <div class="barra"></div>
    <div style="display: flex; width: 10%; margin-left: 8px;" [mat-dialog-close]="false"><img src="../../../assets/cerrar.svg" width="40px" style="cursor: pointer;"></div>  
  </div>
  <div class="container" >  
      <p [innerHTML]="data.text"></p>
      <div class="button-wrapper">      
        <button mat-raised-button class="guardar" [mat-dialog-close]="false">Entendido</button>      
      </div>
    </div>
    
