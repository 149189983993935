import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ventana-emergente-dialog',
  templateUrl: './ventana-emergente-dialog.component.html',
  styleUrls: ['./ventana-emergente-dialog.component.scss']
})
export class VentanaEmergenteDialogComponent implements OnInit {
  ventana = {
    titulo: '',
    nombre: '',
    texto: '',
    link: '',
    imgUrl: ''
  };

  constructor(private httpClient: HttpClient, public dialogRef: MatDialogRef<VentanaEmergenteDialogComponent>,) {
  }

  ngOnInit(): void {
    this.httpClient.get(environment.api + '/ventanaemergente/activa').subscribe(
      (ventana: any) => {
        this.ventana = ventana;
      }
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  gotoLink(url:string){
    if(url == null || url==undefined || url?.trim()==""){
      return;
    }
    window.open(url,'_blank');
  }
}
