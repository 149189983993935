<div class="govco" style="background-color: #0076BE; height: 50px;">
  <div class="cont-govco" style="padding: 0 15px; max-width: 1140px;display: flex;margin: 0 auto;">
  <a role="link" aria-label="Logo Gobierno de Colombia. Te llevará al inicio" class="navbar-brand" href="https://www.gov.co/">
    <img src="https://cdn.www.gov.co/assets/images/logo.svg" height="30" width="140" alt="Logo gov.co" style="margin-top: 10px;">
  </a>
  </div>
</div>
<div class="logo-top-s">
  <div class="logo-h" routerLink="/">
    <img src="../../../assets/1-logo.svg" width="45px">
    <p style="font-size: 30px; color: white; margin-left: 19px; font-weight: bolder">CONALPE</p>
  </div>
  <div class="searc-box">
    <mat-form-field class="input">
      <input matInput placeholder="Buscador" type="text">
      <img src="../../../../assets/search.svg" height="25px" matPrefix alt=""
        style="margin-right: 10px; margin-left: 10px;">
    </mat-form-field>
  </div>
</div>
<div class="container_menu">
  <div class="cont_menu">
      <button mat-button>Transparencia y acceso a la información pública</button>
      <button mat-button>Atención y servicios a la ciudadania</button>
      <button mat-button>Participa</button>
      <button mat-button [matMenuTriggerFor]="tramites">Trámites y servicios</button>
      <mat-menu #tramites="matMenu" class="header-menu">
        <button mat-menu-item routerLink="/tramitesyservicios/matriculas-profesionales">Matrículas profesionales</button>
        <button mat-menu-item routerLink="/tramitesyservicios/certificado">Certificados trámite y vigencia</button>
        <button mat-menu-item routerLink="/tramitesyservicios/duplicado">Duplicados</button>
        <button mat-menu-item routerLink="/tramitesyservicios/Consulta-Inscritos">Estado del trámite</button>
      </mat-menu>
      <button mat-button [matMenuTriggerFor]="inspeccion" >Inspección y vigilancia</button>
      <mat-menu #inspeccion="matMenu" class="header-menu">
        <button mat-menu-item routerLink="/inspeccion-vigilancia/denunciar">Denuncia o queja profesional</button>
        <button mat-menu-item routerLink="/inspeccion-vigilancia/busqueda-procesos">Búsqueda de Procesos</button>
        <button mat-menu-item routerLink="/inspeccion-vigilancia/lista-sancionados">Sancionados</button>
        <button mat-menu-item routerLink="/inspeccion-vigilancia/notificaciones">Notificaciones</button>
      </mat-menu>
      <button mat-button [matMenuTriggerFor]="eventos">Nosotros</button>
      <mat-menu #eventos="matMenu" class="header-menu">
        <button mat-menu-item routerLink="/nosotros/cultura" >Cultura organizacional</button>
        <button mat-menu-item routerLink="/nosotros/historia" >Historia</button>
        <button mat-menu-item routerLink="/nosotros/directivos" >Directivos</button>
        <button mat-menu-item routerLink="/nosotros/marco-legal" >Marco Legal</button>
        <button mat-menu-item routerLink="/nosotros/gestion-institucional" >Gestión Financiera</button>
        <button mat-menu-item routerLink="/nosotros/politicas-manuales" >Gestión Institucional</button>
        <button mat-menu-item routerLink="/nosotros/consejos-colegios" >Otros Consejos y Colegios</button>
        <button mat-menu-item routerLink="/universidadesyeventos/universidades">Universidades</button>
        <button mat-menu-item routerLink="/universidadesyeventos/eventos">Eventos</button>
      </mat-menu>
      <div class="option" routerLink="/tramitesyservicios/Consulta-Inscritos">Consulta de Matrícula</div>
      <div class="option" routerLink="/contacto">Contacto</div>
      <div class="option" routerLink="/intranet">Intranet</div>
  </div>
</div>

