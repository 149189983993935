<div style="background-color: transparent; display: flex; justify-content: flex-end; padding-bottom: 1rem">
  <img style="display: block" src="assets/cerrar_modal.svg" alt="" height="40px" (click)="closeModal()">
</div>

<a (click)="gotoLink(ventana.link)" target="_blank" *ngIf="ventana.imgUrl!=''">
  <img [src]="ventana.imgUrl" alt="" class="image-content">
</a>
<div *ngIf="ventana.imgUrl == ''">
  <a (click)="gotoLink(ventana.link)" style="text-decoration: none">
    <div class="container-div">
      <div class="text-div">
        <p class="title-modal">{{ventana.titulo}}</p>
        <p class="text-modal">{{ventana.texto}}</p>
      </div>
      <button class="btn-green">Ver más</button>
    </div>
  </a>
</div>
