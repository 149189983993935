import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SolicitudesService } from 'src/app/services/solicitudes.service';

@Component({
  selector: 'app-modal-documentos-actuacion',
  templateUrl: './modal-documentos-actuacion.component.html',
  styleUrls: ['./modal-documentos-actuacion.component.scss']
})
export class ModalDocumentosActuacionComponent implements OnInit {
  documentosDenuncia;
  mostrarInternos;
  urlSafe;
  constructor(private solicitudesService:SolicitudesService,private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ModalDocumentosActuacionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.documentosDenuncia = this.data.documentosDenuncia;
    this.mostrarInternos = this.data.mostrarInternos;    
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async VerDocumento(url){
    const result = await this.solicitudesService.getUrlDocumentos(url).toPromise() as any;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(result); 
    window.open(this.urlSafe.changingThisBreaksApplicationSecurity);
  }


}
