// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'http://localhost:5000/api',
  reporteador: '2FPRUEBAS_CONALPE',
  captchaKey: '6LfxFFMaAAAAADxENUTb-3ZiVBFbmb9gcsznZgg5',
  modalInicio: true,
  ambienteLambda:'PRU',
  accessKeyIdLambda:'AKIAUWQ6OINLJC4TU5VC',
  secretAccessKeyLambda:'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',
  bucketTempDocFirmados : 'conalpe-firma-digital-tmp',
  bucketDocumentos : 'conalpe-test-documentos',
  bucketDescargaS3 : 'descarga-archivos-tmp',
  bucketData : 'conalpe-test-data',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone  related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
