import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IntranetComponent} from './intranet/intranet.component';
import {ExternaComponent} from './externa/externa.component';


const routes: Routes = [
  {path: '', component: ExternaComponent, loadChildren: () => import('./externa/externa.module').then( m => m.ExternaModule)},  
  {path: 'intranet', component: IntranetComponent, loadChildren: () => import('./intranet/intranet.module').then( m => m.IntranetModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
