import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule} from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HeaderExternaComponent } from '../externa/header-externa/header-externa.component';
import { RouterModule } from '@angular/router';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderResponsiveComponent } from '../externa/header-responsive/header-responsive.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { ModalInformativoComponent } from './modal-informativo/modal-informativo.component';
import { ModalDocumentosActuacionComponent } from './modal-documentos-actuacion/modal-documentos-actuacion.component';


@NgModule({
  declarations: [
    HeaderExternaComponent,
    HeaderResponsiveComponent,
    MonthPickerComponent,
    ModalInformativoComponent,
    ModalDocumentosActuacionComponent,   
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule
    
  ],
  exports: [
    HeaderExternaComponent,
    HeaderResponsiveComponent,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    NgbCarouselModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MonthPickerComponent,
  ]
})
export class UtilModule {
}
