import { Component, OnInit } from '@angular/core';
import {SideNavService} from "../side-nav.service";
import {VentanaEmergenteDialogComponent} from '../index/ventana-emergente-dialog/ventana-emergente-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AccesibilidadComponent} from '../modales/accesibilidad/accesibilidad.component';

@Component({
  selector: 'app-header-externa',
  templateUrl: './header-externa.component.html',
  styleUrls: ['./header-externa.component.scss']
})
export class HeaderExternaComponent implements OnInit {

  constructor(private  sideNavService: SideNavService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  changeStatus() {
    this.sideNavService.changeScale(true);
  }

  openNarrador() {
    const dialogRef = this.dialog.open(AccesibilidadComponent, {
      panelClass: 'modalpannel'
    });
  }
}
