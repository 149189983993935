import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../side-nav.service';

@Component({
  selector: 'app-header-responsive',
  templateUrl: './header-responsive.component.html',
  styleUrls: ['./header-responsive.component.scss']
})
export class HeaderResponsiveComponent implements OnInit {

  constructor(public sideNavService: SideNavService) {
  }

  ngOnInit() {
  }

  changeStatus() {
    this.sideNavService.changeStatus(true);
  }

}
