import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {VentanaEmergenteDialogComponent} from './externa/index/ventana-emergente-dialog/ventana-emergente-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Route, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'ConalpeWebsite';
  scale = false;
  @ViewChild('recaptcha', {static: true}) recaptchaElement: ElementRef;

  ngOnInit(): void {
  
    var pathActual = window.location.pathname;
    if (environment.modalInicio == true) {
      this.httpClient.get(environment.api + '/ventanaemergente/activa').subscribe(
        (ventana: any) => {

          var RegExp =/Consulta-Inscritos\/\d*$/;
          var RegExpCertificado =/Validar-Certificado-Vigencia\/\d*$/;
          //si estan en la ruta de consulta inscritos y viende desde el QR que envia el numero de la matricula en la ruta no se muestra el modal de la ventana emergente
          if(RegExp.test(pathActual) || RegExpCertificado.test(pathActual) || pathActual.startsWith('/intranet')){
            return;
          }
          //si no hay parametrizadas ventanas emergentes en el sistema no se abre el modal
          if(ventana != null && ventana != undefined){
            this.openDialog();
          }
        }
      );
     
    }

  }

  constructor(public dialog: MatDialog,private httpClient: HttpClient, private router:Router) {
  }

  openDialog() {    
    const dialogRef = this.dialog.open(VentanaEmergenteDialogComponent, {
      panelClass: 'modalpannel'
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
}

