import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  satusScale: Subject<boolean> = new Subject();
  statusSidenav: Subject<boolean> = new Subject();

  constructor() {
  }

  changeStatus(status: boolean) {
    this.statusSidenav.next(status);
  }
  changeScale(status: boolean) {
    this.satusScale.next(status);
  }
}
