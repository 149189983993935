import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeCO from '@angular/common/locales/es-CO';
import {IntranetComponent} from './intranet/intranet.component';
import {UtilModule} from './util/util.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {getPaginatorIntl} from './util/SpanishPaginator';
import { ExternaComponent } from './externa/externa.component';
import { IndexComponent } from './externa/index/index.component';
import {CacheInterceptorService} from './util/cache-interceptor.service';
import {AuthInterceptorService} from './intranet/util-intranet/auth-interceptor.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxCaptchaModule } from 'ngx-captcha';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SpinnerInterceptor} from './util/spinner.interceptor';
import {VentanaEmergenteDialogComponent} from './externa/index/ventana-emergente-dialog/ventana-emergente-dialog.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_ADAPTER_OPTIONS_FACTORY } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(localeCO, 'co');

@NgModule({
  declarations: [
    AppComponent,  
    IntranetComponent,
    ExternaComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UtilModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'co'},
    {provide: MatPaginatorIntl, useValue: getPaginatorIntl()},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {

      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'co'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {

    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }
}
